const apiUrl = (`${process.env.REACT_APP_BASE_URL}/yuni/attempts`);
;

export const fetchAttempts = async () => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch question progress');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching question progress:', error);
    throw error;
  }
};
