import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/common-ui/Header';
import Navigator from './routes';

localStorage.clear();

function App() {
  return (
    <Router>
      <Header />
      <Navigator />
    </Router>
  );
}

export default App;
