import React from 'react';
import Table from '../../components/common-ui/Table/table';
import { roundToTwoDecimalPlaces, getUniqueQuestionIds, getTotalsForQuestionId } from '../../utils/progressFunctions';

const ProgressTable = ({ progressData, questionsData, usersData, responsesData, filterType }) => {
  // Function to create table rows based on filterType
  const createTableRows = () => {
    return filterType === 'userCentric'
      ? progressData.map(progress => {
          const user = usersData.find(user => user._id === progress.userId);
          const userName = user ? user.userCode : 'N/A';
          const question = questionsData.find(q => q._id === progress.questionId);
          const response = responsesData.find(response => response.userId === progress.userId && response.questionId === progress.questionId);
          const answer = response ? response.answer : 'N/A';
          return [
            userName, // Display user name instead of user ID
            question ? question.question : 'N/A',
            progress.attemptCount,
            progress.successCount,
            progress.subject,
            progress.questionType,
            progress.duration ? roundToTwoDecimalPlaces(progress.duration) : 'N/A',
            answer // Add the answer column
          ];
        })
      : getUniqueQuestionIds(progressData).map(questionId => {
          const relevantProgress = progressData.find(progress => progress.questionId === questionId);
          const question = questionsData.find(q => q._id === questionId);
          const { totalAttempts, totalSuccessCount, totalDuration } = getTotalsForQuestionId(progressData, questionId);
          return [
            question ? question.question : 'N/A',
            totalAttempts,
            totalSuccessCount,
            relevantProgress.subject,
            relevantProgress.questionType,
            totalAttempts > 0 ? roundToTwoDecimalPlaces(totalDuration / totalAttempts) : 'N/A'
          ];
        });
  };

  // Define table headers based on filterType
  const headers =
    filterType === 'userCentric'
      ? ['User Name', 'Question', 'Attempt Count', 'Success Count', 'Subject', 'Question Type', 'Duration', 'Answer']
      : ['Question', 'Attempt Count', 'Success Count', 'Subject', 'Question Type', 'Duration'];

  return (
    <div>
      <Table headers={headers} data={createTableRows()} />
    </div>
  );
};

export default ProgressTable;
