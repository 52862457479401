import React, { useEffect, useState } from 'react';

const Table = ({ headers, data, buttonClassName }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [columnWidths, setColumnWidths] = useState([]);

  const recordsPerPage = 20;

  useEffect(() => {
    // Calculate column widths
    const widths = headers.map((_, columnIndex) => {
      return "auto"; // Set initial width to 'auto' to allow content wrapping
    });
    setColumnWidths(widths);
  }, [headers]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(data.length / recordsPerPage);

  return (
    <div>
      <div className="table-container">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="py-2 px-4 border-b" style={{ width: columnWidths[index] }}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="py-2 px-4 border-b" style={{ width: columnWidths[cellIndex] }}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.length > recordsPerPage && (
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`${buttonClassName} ${currentPage === 1 ? 'grayscale' : ''}`}
          >
            Previous
          </button>
          <span className="text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastRecord >= data.length}
            className={`${buttonClassName} ${indexOfLastRecord >= data.length ? 'grayscale' : ''}`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

// Default button styling
Table.defaultProps = {
  buttonClassName: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
};

export default Table;
