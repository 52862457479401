import React, { useEffect, useState } from 'react';
import Dropdown from '../../components/common-ui/Dropdown';
import {
  DIFFICULTY_DROPDOWN_OPTIONS,
  DROPDOWN_DIFFICULTY,
  DROPDOWN_QUESTION_TYPE,
  DROPDOWN_STORY,
  DROPDOWN_SUBJECT,
  QUESTION_TYPE_DROPDOWN_OPTIONS,
  STORY_DROPDOWN_OPTIONS,
  SUBJECT_DROPDOWN_OPTIONS,
} from '../../utils/constants';
import Table from './Table';
import Button from '../../components/common-ui/Button';

import { handleAuthorizationCallback, redirectToAuthorizationEndpoint, validateOrRefreshAccessToken, readDataFromSheet } from '../../services/googleSheets';

const DashBoardViewPage = () => {
  const [subject, setSubject] = useState(null);
  const [story, setStory] = useState(null);
  const [questionType, setQuestionType] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [isPromptDispatched, setPromptState] = useState(false);

  const [isAuthenticated, setAuthStatus] = useState(false);

  const [questions] = useState({});

  // Removed the unused state setter
  // const [isGeneratingQuestions, setGenerationStatus] = useState(false);

  async function GenerateQuestions() {
    console.log("Generating questions");
    let readStatus = await readDataFromSheet(subject, story);
    console.log(readStatus);
    if(!readStatus.success){
      setAuthStatus(false);
      setPromptState(false);
    }
    setPromptState(!isPromptDispatched);
  }

  let reAuthenticate = () => {
    console.log('Re authenticate');
    setAuthStatus(false);
    setPromptState(false);
  };

  async function Authenticate() {
    redirectToAuthorizationEndpoint();
  }

  useEffect(()=>{
    const fetchData = async () => {
      let authStatus = await validateOrRefreshAccessToken();
      setAuthStatus(authStatus);
  
      if (authStatus) { // Changed from isAuthenticated to authStatus
        console.log("returning because it's already authenticated");
        return;
      }
  
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams?.get("code");
      if (authorizationCode) {
        authStatus = await handleAuthorizationCallback(authorizationCode);
        setAuthStatus(authStatus);
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };
  
    fetchData();
  }, []); // Removed isAuthenticated from the dependency array

  return (
    <>
      {isAuthenticated ? (
        <>
          <div className="m-4 text-2xl font-sans font-semibold tracking-tight text-center">Generate and provide feedback for questions from {story}</div>
          <div className="flex content-center bg-gray-200 lg:w-11/12 px-6 pb-4 pt-4 lg:px-2 m-auto mb-4 border-b border-gray-200 shadow-md sm:rounded-lg">
            <Dropdown
              dropdownTitle={DROPDOWN_STORY}
              dropdownOptions={STORY_DROPDOWN_OPTIONS}
              onSelect={(e) => {
                setStory(e);
                setPromptState(false);
              }}
            />
            <Dropdown
              dropdownTitle={DROPDOWN_SUBJECT}
              dropdownOptions={SUBJECT_DROPDOWN_OPTIONS}
              onSelect={(e) => {
                setSubject(e);
                setPromptState(false);
              }}
            />
            <Dropdown
              dropdownTitle={DROPDOWN_QUESTION_TYPE}
              dropdownOptions={QUESTION_TYPE_DROPDOWN_OPTIONS}
              onSelect={(e) => {
                setQuestionType(e);
                setPromptState(false);
              }}
            />
            <Dropdown
              dropdownTitle={DROPDOWN_DIFFICULTY}
              dropdownOptions={DIFFICULTY_DROPDOWN_OPTIONS}
              onSelect={(e) => {
                setDifficulty(e);
                setPromptState(false);
              }}
            />
          </div>
          {isPromptDispatched ? (
            <Table
              questions={questions}
              story={story}
              subject={subject}
              questionType={questionType}
              difficulty={difficulty}
              reAuthenticate={reAuthenticate}
            />
          ) : subject && questionType && difficulty ? (
            <div className="text-center">
              <Button
                // disabled={isGeneratingQuestions}
                // className={isGeneratingQuestions ? 'opacity-50 cursor-not-allowed' : ''}
                buttonName={'Generate Questions'} // Changed to a static button name
                onClick={GenerateQuestions}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div className="flex w-screen justify-center content-center pt-4 pb-4 bg-gray-200 mb-4">
          <Button
            disabled={false} // Changed to false to allow authentication
            buttonName={'Authenticate'}
            onClick={Authenticate}
          />
        </div>
      )}
    </>
  );
};

export default DashBoardViewPage;
