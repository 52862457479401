import axios from "axios";
import { SHEETS } from "../utils/constants";

const authorizationEndpoint = "https://accounts.google.com/o/oauth2/v2/auth";
const tokenEndpoint = "https://oauth2.googleapis.com/token";
const sheetsEndpoint = "https://sheets.googleapis.com/v4/spreadsheets";

const userInfoEndpoint = "https://www.googleapis.com/oauth2/v3/userinfo";

const clientId = "688471376615-bdf4lcslu8i5ltsl9ttcuqaqll2npj63.apps.googleusercontent.com";
const clientSecret = "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";

// const redirectUri = "http://localhost:3000/";
const redirectUri = "https://yunicorn-j5bnihchyq-uc.a.run.app";

const scope = "https://www.googleapis.com/auth/spreadsheets profile";

const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
};

var accessToken = "";
var refreshToken = "";
var expiresIn = "";

function redirectToAuthorizationEndpoint() {
    const authorizationUrl = `${authorizationEndpoint}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
    window.location.href = authorizationUrl;
}

async function validateOrRefreshAccessToken(){
    const storedAccessToken = localStorage.getItem('yunicorn_accessToken');
    const storedExpiresIn = localStorage.getItem('yunicorn_expiresIn');
    const storedRefreshToken = localStorage.getItem('yunicorn_refreshToken');
    if(!storedExpiresIn){
        return false;
    }
    const hasAccessTokenExpired = parseInt(storedExpiresIn) <= 0;
    if(hasAccessTokenExpired){
        await refreshAccessToken(storedRefreshToken);
    } else {
        accessToken = storedAccessToken;
        return true;
    }
}

async function refreshAccessToken(refreshToken){
    try {
        const refreshRequestBody = `client_id=${clientId}&client_secret=${clientSecret}&refresh_token=${refreshToken}&grant_type=refresh_token`;

        const refreshResponse = await axios.post(tokenEndpoint, refreshRequestBody, {headers});

        accessToken = refreshResponse.data.access_token;
        expiresIn = refreshResponse.data.expires_in;

        localStorage.setItem('yunicorn_accessToken', accessToken);
        localStorage.setItem('yunicorn_expiresIn', expiresIn);

        return true;
    } catch (error) {
        console.error("Error refreshing access token:", error);
        return false;
    }
}

async function handleAuthorizationCallback(authorizationCode) {

    const tokenRequestBody = `code=${authorizationCode}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUri}&grant_type=authorization_code`;
    try {
        const tokenResponse = await axios.post(tokenEndpoint, tokenRequestBody, {headers});

        accessToken = tokenResponse.data.access_token;
        refreshToken = tokenResponse.data.refresh_token;
        expiresIn = tokenResponse.data.expires_in;

        localStorage.setItem('yunicorn_accessToken', accessToken);
        localStorage.setItem('yunicorn_refreshToken', refreshToken);
        localStorage.setItem('yunicorn_expiresIn', expiresIn);
        localStorage.setItem('isAuthenticated', true);

        await fetchUserDetails();
        return true;
    } catch (error) {
        console.error("Error exchanging authorization code for access token:", error);
        return false;
    }
    
}

async function fetchUserDetails(){
    try {
        const profileResponse = await axios.get(userInfoEndpoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
        localStorage.setItem('yunicorn_username', profileResponse?.data?.name);
    } catch(error){
        console.error("Unable to fetch user details:", error);
    }
}

function savePromptsFromCSV(csvObject, subject){
    csvObject.forEach(row => {
        if(row[2]){
            localStorage.setItem(`${subject}_${row[0]}_${row[1]}`, row[2]);
        }
    });
}


function convertToCSV(dataObject, questionType, difficulty){
    let formattedCSV = [];
    const userName = localStorage.getItem('yunicorn_username');
    const timeNow = new Date().toLocaleString();
    dataObject.forEach(element => {
        formattedCSV.push([questionType, difficulty, element.text, element.likertRating, element.whyText, element.suggestedChanges, element.difficultyRating, userName, timeNow]);
    });
    return formattedCSV;
}

async function readDataFromSheet(subject, story){
    const range = `prompts_${subject}!A2:D5`;
    const sheetsEndpointWithParams = `${sheetsEndpoint}/${SHEETS[story]}/values/${range}`;
    if(!accessToken){
        accessToken = localStorage.getItem('yunicorn_accessToken')
    }
    if(!accessToken){
        alert("Access token not found!");
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    try {
      const response = await axios.get(sheetsEndpointWithParams, { headers });
      savePromptsFromCSV(response.data.values, subject);
      return { success: true, data: response.data.values };
    } catch (error) {
      console.error("Error reading from Google Sheets:", error);
      return { success: false, error: error.message };
    }
}

async function appendDataToSheet(dataObject, story, subject, questionType, difficulty) {

    const queryParams = new URLSearchParams({
        valueInputOption: "RAW"
    });

    let sheetsRange = `${subject}!A1:I5`;
    let sheetsEndpointModified = `${sheetsEndpoint}/${SHEETS[story]}/values/${sheetsRange}:append`; //sheetsEndpoint.replace("#", sheetsRange);

    const sheetsEndpointWithParams = `${sheetsEndpointModified}?${queryParams.toString()}`;

    const csvData = convertToCSV(dataObject, questionType, difficulty);

    const payload = {
        range: sheetsRange,
        majorDimension: "ROWS",
        values: csvData
    };

    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    try {
        const response = await axios.post(sheetsEndpointWithParams, payload, { headers });
        return { success: true, data: response.data };
    } catch (error) {
        console.error("Error writing to Google Sheets:", error);
        return { success: false, error: error.message };
    }
}

export {
    redirectToAuthorizationEndpoint,
    handleAuthorizationCallback,
    validateOrRefreshAccessToken,
    appendDataToSheet,
    readDataFromSheet
};