import React from 'react';
import Table from '../../components/common-ui/Table/table';

const ResponseTable = ({ questionsData, usersData, responsesData }) => {
  
  const createTableRows = () => {
    return responsesData.map(response => {
      const user = usersData.find(user => user._id === response.userId);
      const userName = user ? user.username : 'N/A';
      const question = questionsData.find(q => q._id === response.questionId);
      return [
        userName, 
        question ? question.question : 'N/A',
        question ? question.subject : 'N/A',
        question ? question.questionType : 'N/A',
        question ? question.difficulty : 'N/A',
        response.answer 
      ];
    });
  };

  // Define table headers
  const headers = [
    'User Name',
    'Question',
    'Subject',
    'Question Type',
    'Difficulty',
    'Answer'
  ];

  return (
    <div>
      <Table headers={headers} data={createTableRows()} />
    </div>
  );
};

export default ResponseTable;
