import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import logo from './assets/yunicorn-logo.png';

const packageJson = require('./../../../package.json');
const packageVersion = packageJson.version;

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const authStatus = localStorage.getItem('isAuthenticated') === 'true';
      setIsAuthenticated(authStatus);
    };

    checkAuth();

    const interval = setInterval(checkAuth, 1000); // Check every 1 second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <nav className="sticky z-50 top-0 flex flex-wrap items-center justify-between pt-3 bg-blue-600 shadow-lg">
      <div className="flex items-center text-white flex-no-shrink">
        <Link to="/">
          <img src={logo} alt="Yunicorn Logo" className="max-h-20" />
        </Link>
        <Link to="/" className="m-4 text-3xl font-sans font-semibold tracking-tight">
          Yunicorn 
        </Link>
        <span className="m-4 text-xl font-sans font-semibold tracking-tight">
          {packageVersion}
        </span>
      </div>
      <div className="flex items-center">
        {isAuthenticated && (
          <>
            <Link to="/questions" className="m-4 text-xl font-sans font-semibold text-white">
              Questions
            </Link>
            <Link to="/openResponses" className="m-4 text-xl font-sans font-semibold text-white">
              Responses
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
