import React from 'react';
import Table from '../../components/common-ui/Table/table';

const QuestionTable = ({ questionsData, storiesData }) => {
  const getStoryName = (storyId) => {
    const matchedStory = storiesData.find((story) => story._id === storyId);
    return matchedStory ? matchedStory.storyName : storyId;
  };

  // Map questionsData to match the structure expected by Table component
  const tableData = questionsData.map((question) => ([
    getStoryName(question.storyId),
    question.subject,
    question.question,
    question.correctAnswer,
    <div>{question.wrongAnswers.map((answer, index) => <div key={index}>{answer}</div>)}</div>,
    question.questionType,
    question.difficulty
  ]));

  // Define table headers
  const headers = ['Story Name', 'Subject', 'Question', 'Correct Answer', 'Wrong Answers', 'Question Type', 'Difficulty'];

  return (
    <div>
      <Table headers={headers} data={tableData}/>
    </div>
  );
};

export default QuestionTable;
