const TableHead = () => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="w-1/5 py-3 text-md font-semibold text-left text-gray-600 px-9 font-sans"
        >
          Generated Question
        </th>
        <th
          scope="col"
          className="w-1/5 py-3 text-md font-semibold whitespace-pre-wrap tracking-wider text-left text-gray-600 px-9 font-sans"
        >
          I would ask this question to my P1 class without changing anything
        </th>
        <th
          scope="col"
          className="w-1/6 px-3 py-3 text-md font-semibold tracking-wider text-left text-gray-600 font-sans"
        >
          Why?
        </th>
        <th
          scope="col"
          className="w-1/5 px-3 py-3 text-md font-semibold tracking-wider text-left text-gray-600 font-sans"
        >
          If you would make changes, how would you change the question?
        </th>
        <th
          scope="col"
          className="w-1/6 px-6 py-3 text-md font-semibold tracking-wider text-left text-gray-600 font-sans"
        >
          If applicable, the question difficulty rating should be re-assigned to
        </th>
      </tr>
    </thead>
  );
};

export default TableHead;
