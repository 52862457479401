import axios from "axios";
import { QUESTION_TYPE } from "../utils/constants";

const CHAT_GPT_API_KEY = "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";
const CHAT_GPT_ENDPOINT = "https://api.openai.com/v1/chat/completions";

const CHAT_GPT_MAX_TOKENS = 1024;
const CHAT_GPT_TEMPERATURE = 1;
const CHAT_GPT_MODEL = "gpt-3.5-turbo";

const headers = {
  Authorization: `Bearer ${CHAT_GPT_API_KEY}`,
};

let payload = {
  messages: [],
  model: CHAT_GPT_MODEL,
  max_tokens: CHAT_GPT_MAX_TOKENS,
  temperature: CHAT_GPT_TEMPERATURE,
};

let currentPrompt = "";
let currentQType = "";

async function generateNewQuestionSet(prompt, questionType) {
  currentPrompt = prompt;
  currentQType = questionType;

  payload.messages = [{ role: "user", content: prompt }];

  try {
    const response = await axios.post(CHAT_GPT_ENDPOINT, payload, { headers });
    const cleanedResponse = sanitizeJSONResponse(response?.data?.choices[0]?.message?.content)
    return parseGeneratedQuestions(cleanedResponse, questionType);
  } catch (error) {
    console.error("Error talking to chat GPT:", error);
    throw error;
  }
}

function sanitizeJSONResponse(json){
  let cleanedJSON = "";
  cleanedJSON = json.replace(/“/g, `"`);
  cleanedJSON = json.replace(/”/g, `"`);
  cleanedJSON = json.replace(/`/g, `"`);
  return cleanedJSON;
}

function parseGeneratedQuestions(gptResponse, questionType) {
  let questions = [];
  if (questionType === QUESTION_TYPE.MCQ) {
    questions = gptResponse.split(/\n{2,}/);
  } else {
    questions = gptResponse.split(/\n/g);
  }

  if (questions.length !== 5) {
    console.error("retrying...");
    return generateNewQuestionSet(currentPrompt, currentQType);
  }
  return questions;
}

export { generateNewQuestionSet };
