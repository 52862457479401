import React, { useEffect, useState } from 'react';
import ResponseTable from './Table';
import Spinner from '../../components/common-ui/Spinner'; 
import { fetchQuestions } from '../../services/questions.service';
import { fetchUsers } from '../../services/users.service';
import { fetchResponses } from '../../services/responses.service';
import { fetchStories } from '../../services/stories.service';
import ProgressFilter from './Filter';

const OpenResponseView = () => {
  const [questionsData, setQuestionsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [responsesData, setResponsesData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    questionType: '',
    story: '',
    subject: '',
    difficulty: '',
  });
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [loading, setLoading] = useState(true); 

  const fetchDataFromApi = async () => {
    try {
      const [questions, users, responses, stories] = await Promise.all([
        fetchQuestions(),
        fetchUsers(),
        fetchResponses(),
        fetchStories()
      ]);
      setQuestionsData(questions);
      setUsersData(users);
      setResponsesData(responses);
      setStoriesData(stories);
      console.log('Questions:', questions);
      console.log('Users:', users);
      console.log('Responses:', responses);
      console.log('Stories:', stories);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters({ ...selectedFilters, [filterName]: value });
  };

  const filteredResponsesData = responsesData.filter(response => {
    const question = questionsData.find(q => q._id === response.questionId);
    const user = usersData.find(u => u._id === response.userId);
    return (
      (!question || (selectedFilters.story ? question.storyId === selectedFilters.story : true)) &&
      (!question || (selectedFilters.subject ? question.subject === selectedFilters.subject : true)) &&
      (!question || (selectedFilters.difficulty ? question.difficulty === selectedFilters.difficulty : true)) &&
      (!userSearchQuery || (user && user.username.toLowerCase().includes(userSearchQuery.toLowerCase())))
    );
  });

  if (loading) {
    return <Spinner />; 
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Student Responses</h1>

      <div className="flex flex-wrap items-center -mx-2 mb-4">
        <ProgressFilter
          label="Story"
          options={storiesData.map(story => ({ id: story._id, name: story.storyName }))}
          selectedValue={selectedFilters.story}
          onChange={value => handleFilterChange('story', value)}
        />

        <ProgressFilter
          label="Subject"
          options={[...new Set(questionsData.map(question => question.subject))]}
          selectedValue={selectedFilters.subject}
          onChange={value => handleFilterChange('subject', value)}
        />

        <ProgressFilter
          label="Difficulty"
          options={[...new Set(questionsData.map(question => question.difficulty))]}
          selectedValue={selectedFilters.difficulty}
          onChange={value => handleFilterChange('difficulty', value)}
        />

        <div className="flex flex-wrap items-center mx-2 mb-4">
          <label className="block text-sm font-medium text-gray-700">User</label>
          <input
            type="text"
            value={userSearchQuery}
            onChange={e => setUserSearchQuery(e.target.value)}
            placeholder="Search users"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <ResponseTable
        responsesData={filteredResponsesData}
        questionsData={questionsData}
        usersData={usersData}
      />
    </div>
  );
};

export default OpenResponseView;
