const apiUrl = (`${process.env.REACT_APP_BASE_URL}/yuni/responses`);
;

export const fetchResponses = async () => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch responses');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching responses:', error);
    throw error;
  }
};
