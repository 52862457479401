import React, { useState } from 'react';
import Textbox from '../../components/common-ui/Textbox';
import Dropdown from '../../components/common-ui/Dropdown';
import {
  DIFFICULTY_DROPDOWN_OPTIONS,
  DROPDOWN_TITLE,
  LIKERT_DROPDOWN_OPTIONS,
} from '../../utils/constants';

const TableRow = (props) => {
  const [state] = useState(props.questionEntry);
  console.log(state);

  return (
    <tr>
      <td className="w-1/5 px-3 py-4 whitespace-pre-wrap text-md font-medium text-gray-900">
        {state.text}
      </td>
      <td className="w-1/5">
        <Dropdown
          dropdownTitle={DROPDOWN_TITLE}
          dropdownOptions={LIKERT_DROPDOWN_OPTIONS}
          onSelect={(e) => {
            const likertValue = e.replace(/\(.*?\)/g, '').trim();
            state.likertRating = likertValue;
            props.onRowUpdate(state);
          }}
        />
      </td>
      <td className="w-1/5 px-2">
        <Textbox
          inputText={state.whyText}
          onChange={(e) => {
              state.whyText = e;
              props.onRowUpdate(state);
          }}
        />
      </td>
      <td className="w-1/5 px-2">
        <Textbox
          inputText={state.suggestedChanges}
          onChange={(e) => {
              state.suggestedChanges = e;
              props.onRowUpdate(state);
          }}
        />
      </td>
      <td>
        <Dropdown
          dropdownTitle={DROPDOWN_TITLE}
          dropdownOptions={DIFFICULTY_DROPDOWN_OPTIONS}
          onSelect={(e) => {
            state.difficultyRating = e;
            props.onRowUpdate(state);
          }}
        />
      </td>
    </tr>
  );
};

export default TableRow;
