import { Menu } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DropdownItems = (props) => {
  return props.menuItems.map((item, index) => {
    return (
      <Menu.Item key={index}>
        {({ active }) => (
          <button
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block w-full px-4 py-2 text-left text-sm'
            )}
            onClick={() => props.onItemClick(item)}
          >
            {item}
          </button>
        )}
      </Menu.Item>
    );
  });
};

export default DropdownItems;
