const Textbox = (props) => {
  return (
    <>
      <textarea
        className="shadow appearance-none border break-all rounded w-full my-4 py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        value={props.inputText}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </>
  );
};

export default Textbox;
