import React, { useEffect, useState } from 'react';
import ProgressTable from './Table';
import { fetchAttempts } from '../../services/questionprogress.service';
import { fetchQuestions } from '../../services/questions.service';
import { fetchUsers } from '../../services/users.service';
import { fetchResponses } from '../../services/responses.service';
import ProgressFilter from './Filter';

const ProgressView = () => {
  const [progressData, setProgressData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [responsesData, setResponsesData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    questionType: '',
    story: '',
    subject: '',
    difficulty: '',
    user: '' 
  });
  const [filterType, setFilterType] = useState('userCentric'); 

  const fetchProgressData = async () => {
    try {
      const data = await fetchAttempts();
      setProgressData(data);
      console.log('Progress Data:', data);
    } catch (error) {
      console.error('Error fetching progress data:', error);
    }
  };

  const fetchQuestionsData = async () => {
    try {
      const data = await fetchQuestions();
      setQuestionsData(data);
      console.log('Questions Data:', data);
    } catch (error) {
      console.error('Error fetching questions data:', error);
    }
  };

  const fetchUsersData = async () => {
    try {
      const data = await fetchUsers();
      setUsersData(data);
      console.log('Users Data:', data);
    } catch (error) {
      console.error('Error fetching users data:', error);
    }
  };

  const fetchResponsesData = async () => {
    try {
      const data = await fetchResponses();
      setResponsesData(data);
      console.log('Response Data:', data);
    } catch (error) {
      console.error('Error fetching response data:', error);
    }
  };

  useEffect(() => {
    fetchProgressData();
    fetchQuestionsData();
    fetchUsersData();
    fetchResponsesData();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters({ ...selectedFilters, [filterName]: value });
  };

  const filteredProgressData = progressData.filter(progress => {
    return (
      (selectedFilters.questionType ? progress.questionType === selectedFilters.questionType : true) &&
      (selectedFilters.story ? progress.storyId === selectedFilters.story : true) &&
      (selectedFilters.subject ? progress.subject === selectedFilters.subject : true) &&
      (selectedFilters.difficulty ? progress.difficulty === selectedFilters.difficulty : true) &&
      (selectedFilters.user ? progress.userId === selectedFilters.user : true) // Filter for user
    );
  });

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Progress View</h1>

      <div className="flex border rounded overflow-hidden mb-5">
  <label className={`flex-1 text-center py-2 cursor-pointer ${filterType === 'userCentric' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
    <input type="radio" name="viewMode" className="sr-only" checked={filterType === 'userCentric'} onChange={() => setFilterType('userCentric')} />
    User Centric
  </label>
  <label className={`flex-1 text-center py-2 cursor-pointer ${filterType === 'questionCentric' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
    <input type="radio" name="viewMode" className="sr-only" checked={filterType === 'questionCentric'} onChange={() => setFilterType('questionCentric')} />
    Question Centric
  </label>
</div>


      <div className="flex flex-wrap items-center -mx-2 mb-4">
        <ProgressFilter
          label="Question Type"
          options={[...new Set(questionsData.map(question => question.questionType))]}
          selectedValue={selectedFilters.questionType}
          onChange={value => handleFilterChange('questionType', value)}
        />

        <ProgressFilter
          label="Subject"
          options={[...new Set(questionsData.map(question => question.subject))]}
          selectedValue={selectedFilters.subject}
          onChange={value => handleFilterChange('subject', value)}
        />

        {filterType === 'userCentric' && progressData && usersData && <ProgressFilter
          label="User"
          options={[...new Set(usersData.filter(user => {
            const progress = progressData.find(progress => progress.userId === user._id);
            return progress;
          }).map(user => ({
            id: user._id,
            name: user.userCode
          })))]}
          selectedValue={selectedFilters.user}
          onChange={value => handleFilterChange('user', value)}
        />}
      </div>

      <ProgressTable
        progressData={filteredProgressData}
        questionsData={questionsData}
        usersData={usersData}
        responsesData={responsesData}
        filterType={filterType}
      />
    </div>
  );
};

export default ProgressView;

