import TableHead from './TableHead';
import QuestionsList from './QuestionsList';
import Spinner from '../../components/common-ui/Spinner';
import React, { useEffect, useState, useCallback } from 'react';

import { generateNewQuestionSet } from '../../services/chatGPT';

import { appendDataToSheet } from '../../services/googleSheets';
import { STORIES } from '../../utils/constants';

function parseQuestions(data) {
  return data.map((item) => {
    return {
      id: item.questionId,
      text: item.text,
      likertRating: null,
      whyText: '',
      suggestedChanges: '',
      difficultyRating: null,
    };
  });
}

const Table = (props) => {

  const [fetchTriggered, setIsFetching] = useState(false);
  const [questions, setQuestions] = useState([]);

  const updatePrompt = useCallback(() => {
    return STORIES[props.story] + localStorage.getItem(`${props.subject}_${props.questionType}_${props.difficulty}`);
  }, [props.story, props.subject, props.questionType, props.difficulty]);

  const generateQuestions = useCallback(async () => {
    try {
      setIsFetching(true);
      const prompt = updatePrompt();
      const response = await generateNewQuestionSet(prompt, props.questionType);
      if (response && response.length > 0) {
        let newQuestionSet = [];
        response.forEach(element => {
          newQuestionSet.push({ questionId: newQuestionSet.length + 1, text: element.trim() });
        });
        setQuestions(newQuestionSet);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [props.questionType, updatePrompt]);

  useEffect(() => {
    generateQuestions();
  }, [generateQuestions]);

  const submitQuestionResponses = async () => {
    let writeStatus = await appendDataToSheet(questions, props.story, props.subject, props.questionType, props.difficulty);
    if (!writeStatus.success) {
      props.reAuthenticate();
      return;
    }
    await generateQuestions();
  };

  const questionEntries = parseQuestions(questions);

  return (
    <>
      {fetchTriggered ? (
        <Spinner />
      ) : (
        <div className="flex flex-col lg:w-11/12 px-6 pb-16 lg:px-0 justify-center m-auto">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-y-visible border-b border-gray-200 shadow-md sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <TableHead />
                  <tbody className="bg-white divide-y divide-gray-200 font-sans">
                    <QuestionsList
                      questionData={questionEntries}
                      onTableUpdate={(e) => setQuestions(e)}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex">

            <button
              className="w-full bg-white hover:bg-blue-500 hover:text-white text-blue-500 font-bold py-2 px-4 rounded mt-4 border-2 border-blue-500 mr-4 border-1"
              onClick={generateQuestions}
            >
              Refresh questions
            </button>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 "
              onClick={submitQuestionResponses}
            >
              Submit & Generate more questions
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
