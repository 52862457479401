export const DROPDOWN_TITLE = 'Options';
export const DROPDOWN_SUBJECT = 'Select Subject';
export const DROPDOWN_STORY =  'Select Story';

export const DROPDOWN_QUESTION_TYPE = 'Select Question Type';

export const DROPDOWN_DIFFICULTY = 'Select Difficulty';
export const LIKERT_DROPDOWN_OPTIONS = [
  '1 (Strongly Disagree)',
  '2 (Disagree)',
  '3 (Neutral)',
  '4 (Agree)',
  '5 (Strongly Agree)',
];

export const QUESTION_TYPE = {
  MCQ: "MCQ",
  OPEN: "Open-ended"
};

export const STORY_DROPDOWN_OPTIONS = ["Lazy Duck", "The King's Cake"];
export const SUBJECT_DROPDOWN_OPTIONS = ['English', 'Math', 'Chinese'];
export const QUESTION_TYPE_DROPDOWN_OPTIONS = ['Open-ended', 'MCQ'];
export const DIFFICULTY_DROPDOWN_OPTIONS = ['Easy', 'Hard'];

export const STORY_LAZY_DUCK = `
  Lazy Duck waddled around the farmyard.
  He watched the animals as they worked.
  On Monday, he watched the cat.
  She prowled up and down looking for mice.
  “Ha, ha, ha, hee, hee, hee! I'm a duck, I'm a duck, I'm a lazy duck. You do the work and I have the luck.”
  On Tuesday, he watched the hens.
  They scratched and scraped, and laid big brown eggs for the farmers' tea.
  “Ha, ha, ha, hee, hee, hee! I'm a duck, I'm a duck, I'm a lazy duck. You do the work and I have the luck.”
  On Wednesday, he watched the dog.
  He ran up and down and round and round getting the sheep.
  “Ha, ha, ha, hee, hee, hee! I'm a duck, I'm a duck, I'm a lazy duck. You do the work and I have the luck.”
  On Thursday, he watched the goats.
  They ran up and down eating weeds and thistles for the farmer.
  “Ha, ha, ha, hee, hee, hee! I'm a duck, I'm a duck, I'm a lazy duck. You do the work and I have the luck.”
  On Friday, he watched the farmers.
  They scritched and scratched in the garden with their hoes.
  The farmers said, “You know that duck, that lazy duck, I think we'll put it in the pot for tea.”
  Lazy Duck ran to tell the animals in the farmyard.
  “Help me! Help me! Help me, please! The farmers say it's the pot for me!”
  “Be a busy duck!” said the cat.
  On Saturday, the farmers went to work in the garden.
  They saw Lazy Duck cleaning up the beetles and bugs.
  “I'm a busy duck, I'm a busy, busy duck, I'm the busiest duck you've seen.”
  “You're a busy duck,” said the farmers.
  “A lucky, busy duck. Busy ducks are not for tea.”
`;

export const STORY_KINGS_CAKE = `
  The king had a cake for his birthday. It had forty-six candles.
  “I will blow the candles out in one puff,” said the king.
  The king… huffed and puffed and waggled his head. He huffed and puffed, and his face went red.
  But the candles would not go out!
  “Stop!” said the queen. “You haven't got enough puff. I will blow the candles out!”
  So the queen… huffed and puffed, and waggled her head. She huffed and puffed, and her face went red.
  But the candles would not go out!
  “Stop!” said the cook. “You haven't got enough puff. I will blow the candles out!”
  So the cook… huffed and puffed, and waggled her head. She huffed and puffed, and her face went red.
  But the candles would not go out!
  The gardener heard the huffing and puffing, and he came running.
  “The candles will not go out,” said the cook. “And the king can't eat his cake.”
  So the gardener got his garden hose.
  He squirted and squirted, and he waggled his head. He squirted and squirted, and his face went red.
  Then… the candles on the cake went out!
  “Now you can eat your cake,” said the gardener.
  “That's not cake!” said the king. “That's soup!”
`

export const STORIES = {
  "Lazy Duck": STORY_LAZY_DUCK,
  "The King's Cake": STORY_KINGS_CAKE
}

export const SHEETS = {
  "Lazy Duck": "1EyUdeFeoc3-v2sRIZeZyDUMqDjC_8AxfNx-p9j5QNko",
  "The King's Cake": "1RyGF8LMQ_dK7iFl3d8h46BliX4ZHjbmal-bFVapiC9Q"
}