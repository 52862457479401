import TableRow from './TableRow';
import { useState } from 'react';

const QuestionList = (props) => {
  const [rows, setRows] = useState(props.questionData);

  const handleRowUpdate = (row) => {
    const updatedRows = rows.map((question) => {
      return question.id === row.id ? row : question;
    });
    setRows(updatedRows);
    props.onTableUpdate(rows);
  };

  // render questions with fetched data
  const renderQuestions = rows.map((question) => (
    <TableRow key={question.id} questionEntry={question} onRowUpdate={handleRowUpdate} />
  ));

  return <>{renderQuestions}</>;
};

export default QuestionList;
