import React, { useEffect, useState } from 'react';
import QuestionTable from './Table';
import Spinner from '../../components/common-ui/Spinner';
import { fetchStories } from '../../services/stories.service';
import { fetchQuestions } from '../../services/questions.service';
import QuestionFilter from './Filter';

const QuestionsView = () => {
  const [data, setData] = useState({ questions: [], stories: [] });
  const [selectedFilters, setSelectedFilters] = useState({
    questionType: '',
    story: '',
    subject: '',
    difficulty: ''
  });
  const [loading, setLoading] = useState(true);

  const fetchDataFromApi = async () => {
    try {
      const [questions, stories] = await Promise.all([fetchQuestions(), fetchStories()]);
      setData({ questions, stories });
      console.log('Questions:', questions);
      console.log('Stories:', stories);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters({ ...selectedFilters, [filterName]: value });
  };

  const filteredQuestions = data.questions.filter(question => {
    return (
      (selectedFilters.questionType ? question.questionType === selectedFilters.questionType : true) &&
      (selectedFilters.story ? question.storyId === selectedFilters.story : true) &&
      (selectedFilters.subject ? question.subject === selectedFilters.subject : true) &&
      (selectedFilters.difficulty ? question.difficulty === selectedFilters.difficulty : true)
    );
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">Questions</h1>

      <div className="flex flex-wrap items-center -mx-2">
        {/* Filter by Question Type */}
        <QuestionFilter
          label="Question Type"
          options={[...new Set(data.questions.map(question => question.questionType))]}
          selectedValue={selectedFilters.questionType}
          onChange={value => handleFilterChange('questionType', value)}
        />

        {/* Filter by Story */}
        <QuestionFilter
          label="Story"
          options={data.stories.map(story => ({ id: story._id, name: story.storyName }))}
          selectedValue={selectedFilters.story}
          onChange={value => handleFilterChange('story', value)}
        />

        {/* Filter by Subject */}
        <QuestionFilter
          label="Subject"
          options={[...new Set(data.questions.map(question => question.subject))]}
          selectedValue={selectedFilters.subject}
          onChange={value => handleFilterChange('subject', value)}
        />

        {/* Filter by Difficulty */}
        <QuestionFilter
          label="Difficulty"
          options={[...new Set(data.questions.map(question => question.difficulty))]}
          selectedValue={selectedFilters.difficulty}
          onChange={value => handleFilterChange('difficulty', value)}
        />
      </div>

      {/* Add space before the table */}
      <div className="mt-8">
        {/* Table structure */}
        <QuestionTable questionsData={filteredQuestions} storiesData={data.stories} />
      </div>
    </div>
  );
};

export default QuestionsView;
