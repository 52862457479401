// QuestionFilter.js
import React from 'react';

const QuestionFilter = ({ label, options, selectedValue, onChange }) => {
  return (
    <div className="flex items-center mx-2">
      <label htmlFor={`${label}Filter`} className="mr-2">{`Filter by ${label}:`}</label>
      <select
        id={`${label}Filter`}
        onChange={e => onChange(e.target.value)}
        value={selectedValue}
        className="p-2 border"
      >
        <option value="">All</option>
        {options.map(option => (
          typeof option === 'object' ? (
            <option key={option.id} value={option.id}>{option.name}</option>
          ) : (
            <option key={option} value={option}>{option}</option>
          )
        ))}
      </select>
    </div>
  );
};

export default QuestionFilter;
