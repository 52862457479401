const Button = (props) => {
  return (
    <button
      className="transition ease-in-out delay-25 bg-white hover:bg-blue-600 hover:text-white text-blue-600 font-semibold py-2 px-4 border border-blue-600 rounded shadow"
      onClick={props.onClick}
    >
      {props.buttonName}
    </button>
  );
};

export default Button;
