// utils.js

export const roundToTwoDecimalPlaces = (value) => {
  return Math.round(value * 100) / 100;
};

export const calculateTotalAttempts = (questionAttempts) => {
  return questionAttempts.reduce((sum, attempt) => sum + attempt.attemptCount, 0);
};

export const calculateTotalSuccessCount = (questionAttempts) => {
  return questionAttempts.reduce((sum, attempt) => sum + attempt.successCount, 0);
};

export const calculateTotalDuration = (questionAttempts) => {
  return questionAttempts.reduce((sum, attempt) => sum + attempt.duration, 0);
};

export const getUniqueQuestionIds = (progressData) => {
  return Array.from(new Set(progressData.map((progress) => progress.questionId)));
};

export const getTotalsForQuestionId = (progressData, questionId) => {
  const questionAttempts = progressData.filter((progress) => progress.questionId === questionId);
  return {
    totalAttempts: calculateTotalAttempts(questionAttempts),
    totalSuccessCount: calculateTotalSuccessCount(questionAttempts),
    totalDuration: calculateTotalDuration(questionAttempts),
  };
};
