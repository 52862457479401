import { Routes, Route, Navigate } from 'react-router-dom';

import DashBoardViewPage from '../pages/DashboardPage';
import QuestionsView from '../pages/QuestionDisplay';
import ProgressView from '../pages/ProgressDisplay';
import OpenResponseView from '../pages/ResponseDisplay';

function Navigator() {
  return (
    <Routes>
      <Route path="/" element={<DashBoardViewPage />} />
      <Route path="/questions" element={<QuestionsView />} />
      <Route path="/questionProgress" element={<ProgressView />} />
      <Route path="/openResponses" element={<OpenResponseView/>}/>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Navigator;
